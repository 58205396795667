import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import { Button } from "react-bootstrap";
import { GiCheckMark } from 'react-icons/gi';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../../shared/styles/common.css"
import "./home.css"

export default function Home() {

  function navto(e, url) {
    e.preventDefault();
    window.location.href = url;
  }

  return (
    <div className="page-wrapper background-wrap">
      <Header />
      <div className="page-content background-content">
        <div className="home-content">
          <div className="home-main-text block-bg-other home-block-1">
            <div className="general-title title-color-white">
              A super simple platform for all your needs!
            </div>
            <div className="general-subtitle home-main-subtitle subtitle-color-white">
              Made to grow your career as an artist / producer.
            </div>
            <div className="home-btn-wrapper general-grid-container">
              <Button className="home-btn general-btn general-grid-item" variant="light"
                onClick={(e) => navto(e, "/playlists")}>
                Find a playlist!
              </Button>
              <Button className="home-btn general-btn general-grid-item" variant="outline-light"
                onClick={(e) => navto(e, "/mastering")}>
                Master your track
              </Button>
            </div>
          </div>
          <Container className="home-block-2">
            <Row className="home-grid-container">
              <Col>
                <div className="home-big-text home-grid-item">
                  Producer <br /> Essentials
                </div>
              </Col>
              <Col className="home-text">
                Explore the Producer Essential's platform and its tools for artists, creators, and, producers.
              </Col>
              {/* <Col>
                <div className="home-text home-grid-item">
                  <GiCheckMark className="checkmark-icon" /> Made for producers and artists <br />
                  <GiCheckMark className="checkmark-icon" /> Finish tracks effortlessly <br />
                  <GiCheckMark className="checkmark-icon" /> Simple to setup <br />
                  <GiCheckMark className="checkmark-icon" /> Easy to gain real traction <br />
                </div>
              </Col> */}
            </Row>
          </Container>
          <div className="home-aboutus"
            onClick={(e) => navto(e, "/about")}>
            <p className="hover-underline-btn">
              Learn more about us
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}