import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import { Button, Col, Container, Row } from "react-bootstrap"
import "../../shared/styles/common.css"
import "./mastering.css"

export default function Mastering() {
  return (
    <div className="page-wrapper contact-background background-wrap">
      <Background />
      <Header />
      <div className="page-content background-content general-wrapper">
        <h2 className="general-title title-color-dark">
          Mastering
        </h2>
        <h3 className="general-subtitle title-color-dark-2">
          Get your track mixed and mastered by our experienced team.
        </h3>
        <Container>
          <Row style={{ paddingTop: 50, paddingLeft: 30, paddingRight: 30 }}>
            <Col style={{ minWidth: 200, textAlign: "left" }}>
              <h4 className="general-subtitle-h4 text-black fw-bold" style={{ textAlign: "left" }}>
                Experience
              </h4>
              <p className=" text-black-50">
                Over <b> 300+ </b> tracks mixed, mastered and signed.
              </p>
            </Col>
            <Col style={{ minWidth: 200, textAlign: "left" }}>
              <h4 className="general-subtitle-h4 text-black fw-bold" style={{ textAlign: "left" }}>
                Speedy Delivery
              </h4>
              <p className=" text-black-50">
                <b> Same week </b> track delivery, dependant on revisions.
              </p>
            </Col>
            <Col style={{ minWidth: 200, textAlign: "left" }}>
              <h4 h4 className="general-subtitle-h4 text-black fw-bold" style={{ textAlign: "left" }}>
                Master Only Price
              </h4>
              <p className=" text-black-50">
                <b> $120 </b>USD which includes 3 revisions.
              </p>
            </Col>
            <Col style={{ minWidth: 200, textAlign: "left" }}>
              <h4 className="general-subtitle-h4 text-black fw-bold" style={{ textAlign: "left" }}>
                Mix & Master Price
              </h4>
              <p className=" text-black-50">
                <b> $200 </b> USD which includes 3 revisions.
              </p>
            </Col>
          </Row>
        </Container>

        <h3 className="general-subtitle title-color-dark-2"
          style={{ marginTop: 50 }}>
          Fill out the form below for free and we'll be in touch.
        </h3>

        <Button className="home-btn general-btn general-grid-item w-25"
          style={{ marginTop: 50, minWidth: 200 }}
          variant="outline-dark"
          onClick={() => window.location.href = "https://forms.gle/qcAGjQePCE4Xi6eVA"}>
          Submit here!
        </Button>

      </div>
      <Footer />
    </div >
  );
}