import axios from "axios";
import * as urls from "./constants/url"
import { titleCase } from "./utils/utils"

const enviroment = "prod";

export async function GetCheckout(priceId) {
    const checkoutUrl = urls.checkout;
    const body = {
        id: priceId,
        success: "https://producer-essentials.com/success",
        failure: "https://producer-essentials.com/playlists",
        enviroment: enviroment
    };

    let response = await axios.post(checkoutUrl, body);
    if (response.data) {
        location.href = response.data.url;
    }
}

export async function GetPlaylist(id) {
    const productUrl = urls.packs + `?id=${id}`;
    const body = {
        enviroment: enviroment
    };

    let playlist = [];
    await axios.post(productUrl, body).then((response) => {
        if (!response.data || !response.data.value === null) {
            return playlist;
        }
        playlist = response.data;
        playlist.stripe = response.data.stripe.data[0];
    });
    return playlist;
}

export async function GetPlaylists() {
    const productUrl = urls.packs;
    const body = {
        enviroment: enviroment
    };

    let packs = [];
    await axios.post(productUrl, body).then((response) => {
        packs = response.data.data;
    });

    let productList = [];
    packs.forEach((data, i) => {
        let product = {
            priceId: data.id,
            urlId: data.product.metadata.urlId,
            name: titleCase(data.product.name),
            image: data.product.images[0],
            description: data.product.description,
            price: (data.unit_amount / 100).toFixed(2),
            currency: data.currency,
            genre: data.product.metadata.genre.split(",")
        };
        productList.push(product);
    });
    return productList;
}