import * as React from 'react';
import "./comingSoon.css"

export default function comingSoon(props) {
  return (
    <div className="general-wrapper">
      <div className="general-title title-color-dark">
        {props.title}
      </div>
      <div className="general-subtitle coming-soon-text title-color-dark">
        More stuff coming soon.
        Stay tuned.
      </div>
    </div>
  );
}