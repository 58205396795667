import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FaInstagram, FaSpotify } from 'react-icons/fa';
import logo from '../../images/pe-logo-blk.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css';

export default function Header() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 765);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 765);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Navbar className="header-wrapper" collapseOnSelect expand="md" bg="light" variant="light">
      <Navbar.Brand className="pr-10" href="/">
        <img src={logo} alt="Producer Essentials Logo" height="30" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Link className="header-button topper-button" to="/mastering" >Mastering</Link>
          <Link className="header-button" to="/playlists">Playlists</Link>
        </Nav>
        {isDesktop ? (
          <Nav>
            <Nav.Link
              href="https://www.instagram.com/producer_essentials_dot_com/">
              <FaInstagram className="header-social-icon" />
            </Nav.Link>
            <Nav.Link
              href="https://open.spotify.com/user/31c3rgrgk3253nqfrycksn63jn34">
              <FaSpotify className="header-social-icon" />
            </Nav.Link>
          </Nav>
        ) : (
          <></>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}