import "./background.css"

export default function Background() {
  return (
    <>
      <div
        className="background-bg"
      ></div>
    </>
  );
} ``