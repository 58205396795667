import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import NotFound from "./routes/404/notFound";
import Mastering from "./routes/mastering/mastering";
import Playlists from "./routes/playlists/playlists";
import PlaylistDetails from "./routes/playlists/playlistDetails";
import Failure from "./routes/failure/failure";
import Success from "./routes/success/success";
import About from "./routes/about/about";
import "./index.css"

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<App />} />
      <Route path="mastering" element={<Mastering />} />
      <Route path="playlists" element={<Playlists />} />
      <Route path="playlists/:id" element={<PlaylistDetails />} />
      <Route path="about" element={<About />} />
      <Route path="success" element={<Success />} />
      <Route path="failure" element={<Failure />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);