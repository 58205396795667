import Card from "react-bootstrap/Card";
import React from 'react';
import { Badge, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../shared/styles/common.css";
import "./playlistList.css"

export default function PackList(props) {
  return (
    <div className="grid-container">
      {props.products.map((product) => (
        <Link
          key={product.urlId}
          to={{
            pathname: `${product.urlId}`,
            query: `${product.urlId}`,
          }}>
          <Card className="grid-item pack-wrapper text-center" bg="light" border="light">
            <Card.Img className="pack-img" variant="top" src={product.image} />
            <Card.Body>
              <Card.Text className="packlistitem-name">
                {product.name}
                <div>
                  {product.genre.map((genre, i) => (
                    <Badge
                      key={i}
                      className="genre-badge"
                      bg="secondary"
                    >
                      {genre}
                    </Badge>
                  ))}
                </div>
              </Card.Text>

            </Card.Body>
          </Card>
        </Link>
      ))}
    </div>
  );
}