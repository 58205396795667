import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Spinner, Col, Row } from 'react-bootstrap';
import Background from "../../shared/components/background/background"
import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Stripe from "../../shared/components/stripe/stripeContainer"
import SubmitButton from "../../shared/components/submit-button/submitContainer"
import Spotify from "react-spotify-embed"
import { GetPlaylist } from '../../api/API';
import "../../shared/styles/common.css";
import "./playlistDetails.css"

export default function PlaylistDetails() {
  const [pack, setPack] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getProductDetails();
  }, []);

  async function getProductDetails() {
    //local 
    //let stripeData = localData;
    //prod
    let stripeData = await GetPlaylist(id);
    console.log(stripeData)
    if (stripeData.stripe === undefined) {
      navigate("../pack-not-found", { replace: true });
    }
    stripeData.stripe.price =
      (stripeData.stripe.unit_amount / 100).toFixed(2);
    setPack(stripeData)
    setLoading(false);
  }

  return (
    <div className="page-wrapper contact-background background-wrap">
      <Background />
      <Header />
      <div className="page-content background-content music-wrapper general-wrapper">
        {loading ?
          <p className="general-spinner-wrapper">
            <Spinner className="general-spinner" animation="border" />
          </p> : <>
            <div>
              <div className="grid-container-pack-desktop">
                <div className='block-bg-title'>
                  <h2 className="general-title title-color-dark">
                    {pack.stripe.product.name}
                  </h2>
                  <h3 className="general-subtitle title-color-dark">
                    {pack.stripe.product.description}
                  </h3>
                </div>
              </div>
              <div className="grid-container-pack-desktop">
                <div className="image-group">
                  <img className="pack-image grid-item-pack-desktop" src={pack.stripe.product.images[0]} />
                  <div className="buy-now-btn pt-2">
                    {/* <Stripe priceId={pack.stripe.id} /> */}
                    <SubmitButton priceId={pack.stripe.id} />
                  </div>
                  <h3 className="pack-price" >
                    ${pack.stripe.price} USD
                  </h3>
                </div>
                <div className="grid-item-pack-desktop">
                  <div className="playlist-spotify-wrapper" >
                    <Spotify className="playlist-spotify"
                      link={pack.stripe.product.metadata.playlistLink} />
                  </div>
                </div>
                <Container className="pack-description-wrapper">
                  <Col className="general-grid-container">
                    <Row className="block-bg-playlists-question general-grid-item bg-block-lb">
                      <h2 className="general-title title-color-dark mx-lg-0">
                        How do I submit my track?
                      </h2>
                      <h3 className="general-subtitle title-color-dark mx-lg-0">
                        1. Submit the track via the form above.
                      </h3>
                      <h3 className="general-subtitle title-color-dark mx-lg-0">
                        2. We'll send you an email if the track fits.
                      </h3>
                      <h3 className="general-subtitle pb-4 mb-5 title-color-dark mx-lg-0">
                        3. Use the link in the email to pay for the track placement.
                      </h3>
                    </Row>
                    <Row className="block-bg-playlists-question general-grid-item bg-block-db">
                      <h2 className="general-title mx-lg-0">
                        Where does the money go?
                      </h2>
                      <h3 className="general-subtitle mx-lg-0">
                        80% of the money you send will be used to run
                        optimized ads to get more people to your track.
                      </h3>
                      <h3 className="general-subtitle mx-lg-0">
                        Every placement donation is used to run ads.
                      </h3>
                      <h3 className="general-subtitle pb-4 mb-5 mx-lg-0">
                        Each playlists has a price based on it's engagement.
                      </h3>
                    </Row>
                  </Col>
                  <h3 className="general-subtitle title-color-dark">
                    Want to know more?
                  </h3>
                  <h4 className="general-subtitle-h4">
                    Feel free to reach out to - mario@producer-essentials.com
                  </h4>
                </Container>
              </div>
            </div>
          </>}
      </div>
      <Footer />
    </div>
  );
}

const localData = {
  "stripe": {
    "id": "price_1LtLdwI2j2miQIDd4Wd8ccid",
    "object": "price",
    "active": true,
    "billing_scheme": "per_unit",
    "created": 1665883676,
    "currency": "usd",
    "custom_unit_amount": null,
    "livemode": false,
    "lookup_key": null,
    "metadata": {
      "urlId": "running-beats-playlist"
    },
    "nickname": null,
    "product": {
      "id": "prod_McapAsg8QjIs6F",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1665883675,
      "default_price": "price_1LtLdwI2j2miQIDd4Wd8ccid",
      "description": "Tracks that kick you into high gear.",
      "images": [
        "https://files.stripe.com/links/MDB8YWNjdF8xTGlGYmtJMmoybWlRSURkfGZsX3Rlc3RfVkVwcDZRZTdOa1FES25RQWpvOFR2a3p5002zFyWQkG"
      ],
      "livemode": false,
      "metadata": {
        "playlistLink": "https://open.spotify.com/playlist/4kI6rerxJKkMyuH0z80ohM?si=82edbab608d34279",
        "type": "playlist",
        "urlId": "running-beats-playlist"
      },
      "name": "Face paced running beats",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_20030000",
      "type": "service",
      "unit_label": null,
      "updated": 1668322359,
      "url": null
    },
    "recurring": null,
    "tax_behavior": "inclusive",
    "tiers_mode": null,
    "transform_quantity": null,
    "type": "one_time",
    "unit_amount": 2000,
    "unit_amount_decimal": "2000",
    "price": "20.00"
  },
  "firestore": "firestoreData"
}