import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import "./submitbutton.css"

const formLink = "https://forms.gle/CesQYWw8RSzTfsDn6";

export const SubmitButton = (props) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        location.href = formLink;
        setLoading(false);
    };

    return (
        <Button className="submit-btn-button" variant="success" onClick={handleSubmit}>
            {loading ?
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                : "Submit Now"}
        </Button>
    );
};