import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import ComingSoon from "../../shared/components/comingSoon/comingSoon"
import "../../shared/styles/common.css"
import "./about.css"

export default function About() {
  return (
    <div className="page-wrapper contact-background background-wrap">
      <Background />
      <Header />
      <ComingSoon title="About Us" />
      <Footer />
    </div>
  );
}