import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import Comingsoon from "../../shared/components/comingSoon/comingSoon"
import PlaylistList from "./playlistList"
import React, { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap"
import { GiCheckMark } from 'react-icons/gi';
import { GetPlaylists } from "../../api/API";
import "../../shared/styles/common.css"

export default function Packs() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noPacks, setNoPacks] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  async function getProducts() {
    //local
    //let productData = productDataLocal;
    //prod
    let productData = await GetPlaylists();
    setProducts(productData);
    setLoading(false);
    if (productData.length === 0) {
      setNoPacks(false);
    }
  }

  return (
    <div className="page-wrapper contact-background background-wrap">
      <Background />
      <Header />
      <div className="page-content background-content general-wrapper">
        <h2 className="general-title title-color-dark">
          Playlists
        </h2>
        <h3 className="general-subtitle title-color-dark-2">
          Discover playlists that suit your tracks and will help them grow.
        </h3>
        <h4 className="general-subtitle-h4 pt-4 general-grid-container">
          <div className="general-grid-item">
            <GiCheckMark className="checkmark-icon" />
            Safe and secure
          </div>
          <div className="general-grid-item">
            <GiCheckMark className="checkmark-icon" />
            Speedy approval
          </div>
          <div className="general-grid-item">
            <GiCheckMark className="checkmark-icon" />
            24/7 online support
          </div>
        </h4>
        <div className='block-bg-other'>
          {loading ?
            <p className="general-spinner-wrapper">
              <Spinner className="general-spinner" animation="border" />
            </p> : <>
              <div className="page-content background-content">
                <PlaylistList key={products} products={products} />
              </div>
            </>}
          {noPacks && !loading ? <Comingsoon /> : <></>}
        </div>
      </div >
      <Footer />
    </div >
  );
}

const productDataLocal = [
  {
    currency: "usd",
    description: "Just a pack that will help with testing. Not sure how long this description will be.",
    image: "https://files.stripe.com/links/MDB8YWNjdF8xS244YWFFcW9wOXVsS285fGZsX3Rlc3RfQTd0cmViR1dmQ0pBMlBpRFF4empBOW5h00FIo9H5pu",
    name: "Test playlist",
    price: 2500,
    priceId: "price_1Ks0W4Eqop9ulKo91AzCREJq",
    urlId: "test-pack",
  },
  {
    currency: "usd",
    description: "Just a pack that will help with testing. Not sure how long this description will be.",
    image: "https://files.stripe.com/links/MDB8YWNjdF8xS244YWFFcW9wOXVsS285fGZsX3Rlc3RfQTd0cmViR1dmQ0pBMlBpRFF4empBOW5h00FIo9H5pu",
    name: "A longer pack name",
    price: 2500,
    priceId: "price_1Ks0W4Eqop9ulKo91AzCREJq",
    urlId: "longer-test-pack",
  },
  {
    currency: "usd",
    description: "Just a pack that will help with testing. Not sure how long this description will be.",
    image: "https://files.stripe.com/links/MDB8YWNjdF8xS244YWFFcW9wOXVsS285fGZsX3Rlc3RfQTd0cmViR1dmQ0pBMlBpRFF4empBOW5h00FIo9H5pu",
    name: "Playlist Name",
    price: 2500,
    priceId: "price_1Ks0W4Eqop9ulKo91AzCREJq",
    urlId: "pack-example",
  }];